<template>
    <div id="category">
        <scrollview @onRefresh="onRefresh" @onReload="onReload" :height="pageHeight">
            <div class="category-header">
                <searchV1
                        class="search"
                        v-model="search"
                        :isDisabled="true"
                        @onClick="goSearch"
                ></searchV1>
            </div>
            <div class="swiper-container">
                <nut-swiper
                        class="swiper"
                        direction="horizontal"
                        :swiperData="banners"
                        :loop="true"
                >
                    <div
                            v-for="(item, index) in banners"
                            :key="index"
                            class="nut-swiper-slide"
                            @click="goToPage(item)"
                    >
                        <img :src="item.img_path" style="width: 100%; height: 100%"/>
                    </div>
                </nut-swiper>
            </div>

            <div class="catagory-container">
                <pageMenu1 :list="menuList" @onClick="onMenuClick"></pageMenu1>
            </div>

            <div class="page-bottom-container">
                <div class="l-boxs group-buying-today">
                    <div class="group-buying-today-header">

                        <div class="today-header-text">
                            <img :src="require('@/static/image/icons/c-hot.png')" style="width: 16px;height: 16px"/>
                            <div style="margin-left:5px;">今日团购</div>
                        </div>
                        <div class="today-header-text-second" @click="onCellClick">
                            查看更多
                            <img :src="require('@/static/image/icons/c-more.png')" style="width: 5px;height: 10px"/>
                        </div>
                    </div>

                    <gridV2
                            class="group-buying-today-body"
                            style="height: auto; justify-content:start"
                            :col="3"
                            colGap="9px"
                            rowGap="10px"
                    >
                        <GoodsInfo
                                v-for="(item, index) in BuyingsList"
                                :key="index"
                                :url="item.cover"
                                :title="item.title"
                                :product_price="item.product_price"
                                :market_price="item.market_price"
                                :param="item"
                                @onClick="goDetail"
                                class="box-m-b-10"
                        ></GoodsInfo>
                    </gridV2>

                </div>
                <cellV1 :leftIcon="require('@/static/image/icons/cc-current.png')" class="box-m-t-18" rightIcon=""
                        leftText="近期开团" rightText=""></cellV1>

                <goodsCurrent style="margin-top: 10px" :goodsList="CurrentList"></goodsCurrent>
            </div>
        </scrollview>
    </div>


</template>
<script>
  import searchV1 from '@T/community/search/searchV1.vue'
  import goodsV1 from '@T/community/goods/goodsV1.vue'
  import GoodsInfo from '@T/community/goods/GoodsInfo.vue'
  import pageMenu1 from '@T/community/menu/pageMenu1.vue'
  import goodsCurrent from '@T/community/goodsList/goodsCurrent'
  import menuV2 from '@T/community/menu/menuV2.vue'
  import cellV1 from '@T/community/cell/cellV1.vue'
  import gridV1 from '@/uicomponent/grid/gridV1.vue'
  import gridV2 from '@/uicomponent/grid/gridV2.vue'

  export default {
    components: {
      searchV1,
      pageMenu1,
      GoodsInfo,
      menuV2,
      cellV1,
      goodsV1,
      gridV1,
      gridV2,
      goodsCurrent,
    },
    data () {
      return {
        search: '',
        banners: [],
        menuList: [],
        goodsList: [],
        BuyingsList: [],
        CurrentList: [],
        pageHeight: 0,
        scrollHeight: 0,
        currentParam: {
          page: 1,
          page_size: 10
        }
      }
    },
    mounted () {
      this.pageHeight = window.innerHeight
    },
    created () {
      document.title = '社区商城'
      this.getSwiperList()
      this.getMenuList()
      //this.getHotGoodsList()
      this.groupBuyingToday()
      this.groupBuyingCurrent(this.currentParam)
    },
    methods: {
      goSearch () {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/community/search',
          })
        } else {
          this.$router.push({path: 'search'})
        }
      },
      onMenuClick (e) {
        let type = e.item?.type || 0
        console.log(e)
        if(type == 0){
          if (this.$platform.wxsdk.isWechat()) {
            let query = encodeURIComponent(JSON.stringify(e))
            this.$platform.wxsdk.wxRoute({
              type: 'navigateTo',
              url: '/web/community/category?query=' + query,
            })
          } else {
            this.$router.push({path: 'category', query: e})
          }
        }else if(type == 1){
          //跳转到卡券列表页面
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/couponCard/lists',
          })
        }


      },
      onCellClick () {
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/community/today-category?category_type=3',
          })
        } else {
          this.$router.push({path: 'today-category?category_type=3'})
        }
      },
      async getSwiperList () {
        try {
          let query = await this.$api.community.home.getSwiperList()
          // console.log(1111, query.data);
          this.banners = query.data
        } catch (error) {
          console.log('banner error', error)
        }
      },
      async getMenuList () {
        try {
          let query = await this.$api.community.home.getGoodsCatalog()
          //console.log(1111, query.data);
          this.menuList = query.data

        } catch (error) {
          console.log('menuList error', error)
        }
      },
      /*async getHotGoodsList () {
        try {
          let query = await this.$api.community.home.getHotGoodsList()
          // console.log(1111, query.data);
          this.goodsList = query.data
        } catch (error) {
          console.log('goodsList error', error)
        }
      },*/
      async groupBuyingToday () {
        try {
          let params = {
            page: 1,
            page_size: 3,
          }
          let query = await this.$api.community.home.groupBuyingToday(params)
          // console.log(1111, query.data);
          this.BuyingsList = query.data
        } catch (error) {
          console.log('BuyingsList error', error)
        }
      },
      async groupBuyingCurrent (currentParam) {
        try {
          let params = currentParam
          let query = await this.$api.community.home.groupBuyingCurrent(params)
          let newData = query.data
          if (newData.length > 0) {
            newData.forEach((item, index) => {
              this.CurrentList.push(item)
            })
          }

        } catch (error) {
          console.log('BuyingsList error', error)
        }
      },
      goDetail (e) {
        let goodsId = e.id
        if (this.$platform.wxsdk.isWechat()) {
          let query = {
            goodsId: goodsId,
          }
          query = encodeURIComponent(JSON.stringify(query))
          console.log('/web/community/goods-detail?query=' + query)
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/community/goods-detail?query=' + query,
          })
        } else {
          this.$router.push({
            path: 'goods-detail',
            query: {goodsId: goodsId},
          })
        }
      },
      goToPage (item) {
        let type = item?.open_type || ''
        let path = item?.url || ''
        console.log('type == \'page\'', type == 'page')
        console.log('path', path)
        if (type == 'page') {
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: path,
          })
        }
      },
      //触底
      onRefresh (done) {
        /*this.groupBuyingToday()
        this.groupBuyingCurrent(this.currentParam)*/
      },
      /**
       * step 当前加载结束
       * over 没有更多数据了
       */
      onReload (step, over) {

        this.currentParam.page += 1
        this.groupBuyingCurrent(this.currentParam).finally(() => {
          if (this.CurrentList.length < this.currentParam.page * this.currentParam.page_size) {
            console.log('over')
            over()
          } else {
            console.log('step')
            step()
          }
        })

      },
    },
  }
</script>
<style lang="scss" scoped>

    #category {
        width: 100%;
        background: #f6f6f6;
        display: flex;
        flex-direction: column;

        .category-header {
            flex: 1;
            padding: 5px 15px;
            background: #fff;

            .search {
                width: 345px;
                height: 36px;
            }
        }

        .swiper-container {
            flex: 1;
            background: #fff;
            padding: 5px 15px;

            .swiper {
                width: 100%;
                height: 115px;
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                border-radius: 8px;
            }

            .nut-swiper-slide {
                -webkit-border-radius: 8px;
                -moz-border-radius: 8px;
                border-radius: 8px;
            }
        }

        .catagory-container {
            background: #fff;
            padding: 5px 15px 0 15px;
        }

        .page-bottom-container {
            background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);
            padding: 0 15px;
        }
    }

    .group-buying-today {
        border: 0px solid red;
        border-radius: 4px;

    }

    .group-buying-today-header {
        width: 345px;
        height: 39px;
        background: linear-gradient(180deg, rgba(244, 106, 23, 0.49) 0%, rgba(251, 163, 50, 0) 100%);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .today-header-text {
        margin-left: 13px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #E40000;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .today-header-text-second {
        margin-right: 13px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #E40000;
        line-height: 39px;
    }

    .group-buying-today-body {
        border: 0px solid red;
        padding-top: 10px;
        background-color: #ffffff;

    }

    /deep/ .right_img {
        display: none;
    }

    /deep/ .left_img {
        background-color: #ffffff;
        width: 18px;
        height: 15px
    }


</style>
